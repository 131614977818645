import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'rlk';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://rlk-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://rlk-stg-dealer-api.unikey.com', 'v5', 'rlk'.toUpperCase(), { enableLogging: environment === 'development', preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', '24fe54e2-9bb9-4e75-9971-2b768f8b6f60', '1jqudSXgzxvZMsebxpzTzjNYxdjU9HY9R0cIm/vKTzhW4GHTTcQT27CM4i3iVY1NfntT97mOOZJ9mIu1GaD/IQ==', { enableLogging: environment === 'development' && false, preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint });

export const captchaSiteKey: string = '6LfUD6oUAAAAAGQSSAzBHxIOESRvT6s2bJQAVN-Y';
export const captchaSecretKey: string = '6LfUD6oUAAAAAHLb3Ldy570J4GGLBdHQKCdNvzSB';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://www.unikey.com/lets-connect/';
export const deeplinkDomain = 'https://rlk-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = 'e52ab274-89e3-443b-aad4-cc448ca24155';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/MobileKey-1';
export const googlePlayStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/MobileKey-Staging';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = '24fe54e2-9bb9-4e75-9971-2b768f8b6f60';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://rlk-stg-web.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = '1jqudSXgzxvZMsebxpzTzjNYxdjU9HY9R0cIm/vKTzhW4GHTTcQT27CM4i3iVY1NfntT97mOOZJ9mIu1GaD/IQ==';
export const oidcPartnerBrandId: string = '6daca4a8-aa83-44fe-aaad-3394d50d30a9';
export const oidcPartnerBrandCode: string = 'RLK';
export const oidcSilentRedirectUri: string = 'https://rlk-stg-web.unikey.com/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '33792';
export const releaseId: string = 'Release-279';
export const cdpVersion: string = '5.3.1';
export const commonsVersion: string = '7.5.1';
export const desiredLocales: string = 'en,es,ja,fr,pt';